import React from 'react';
import { AxiosError } from 'axios';
import { PageTitle } from './PageLayout/PageTitle';
import { PageContent } from './PageLayout/PageContent';

export interface IErrorPageProps {
    error: Error;
}

export const ErrorPage: React.FC<IErrorPageProps> = (
    props: IErrorPageProps
) => {
    var message = props.error.message;

    // Show nicer message if we have details about the HTTP response
    const status = (props.error as AxiosError).response?.status;
    switch (status) {
        case 401:
            message = 'The provided access token could not be validated.';
            break;
        case 403:
            message = 'You are not authorized to view this data.';
            break;
        // Could add more...
    }

    return (
        <div className="ErrorPage-root">
            <PageTitle title="Error" />

            <PageContent>{message}</PageContent>
        </div>
    );
};
