import React from 'react';
import { PageTitle } from '../../HubLayout/components/PageLayout/PageTitle';
import { PageContent } from '../../HubLayout/components/PageLayout/PageContent';

export const NotFoundPage: React.FC = () => {
    return (
        <div className="NotFoundPage-root">
            <PageTitle title="Not Found" />

            <PageContent>
                We can't seem to find what you're looking for.
            </PageContent>
        </div>
    );
};
