import React, {
    useState,
    useEffect,
    useRef,
    useContext,
    Dispatch,
    SetStateAction,
} from 'react';
import {
    ShimmeredDetailsList,
    DetailsListLayoutMode,
    IColumn,
    Selection,
    Text,
    CheckboxVisibility,
    Coachmark,
    TeachingBubbleContent,
    Stack,
    IconButton,
    FontSizes,
    ITeachingBubbleStyles,
    FontWeights,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
    columnSort,
    commonGridStyle,
    convertToUserFriendlyByteString,
    filterList,
} from '../../../Common/util/tableUtils';
import { InternalLink } from '../../../Common/components/Links';
import { FilterBox } from '../../../Common/components/FilterBox';
import { dateLocaleStringWithTimeZone } from '../../../Common/util/DateUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { getArtifactDetailsPath } from '../../util/BuildUtils';
import { Artifact } from '../../models/Artifact';
import { ServiceType } from '../../../ABSM/models/ServiceType';
import { CompletionIndicatorIcon } from '../MultistageReleaseData/Display/Status/CompletionIndicatorIcon';
import { ReplicationStatusDisplay } from './ReplicationStatusDisplay';
import { TimelineItem } from '../../models/Timeline';
import {
    getCurrentReplicationTimeline,
    getReleaseDetailsPath,
} from '../../util/ReleaseUtils';
import { IButtonStyles } from 'office-ui-fabric-react';

interface IArtifactReleaseListProps {
    list: Artifact[];
    isLoaded: boolean;
    setCurrentFlightTimeline?: Dispatch<
        SetStateAction<TimelineItem[] | undefined>
    >;
    backgroundColor?: string;
    serviceType?: ServiceType;
    showTitle?: boolean;
    showFilterBox?: boolean;
}

export const ArtifactList: React.FC<IArtifactReleaseListProps> = (
    props: IArtifactReleaseListProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [columnsList, setColumnsList] = useState<IColumn[]>([]);
    const [items, setItems] = useState<Artifact[]>([]); // current version of the list - filtered or sorted
    const [sortedItems, setSortedItems] = useState<Artifact[]>([]);
    const [selectedItem, setSelectedItem] = useState<Artifact | undefined>(
        undefined
    );
    const selection = new Selection({
        onSelectionChanged: () => {
            if (selection.getSelection()[0]) {
                setSelectedItem(selection.getSelection()[0] as Artifact);
            }
        },
        onItemsChanged: () => {
            //set default selection to first item in list
            selection.setIndexSelected(0, true, true);
        },
    });
    // Coachmark variables
    const coachmarkTarget = React.useRef<HTMLDivElement>(null);
    const [
        isCoachmarkVisible,
        { setFalse: hideCoachmark, setTrue: showCoachmark },
    ] = useBoolean(false);
    const helpButtonStyles: Partial<IButtonStyles> = {
        root: {
            padding: '0px',
            margin: '0px',
        },
        icon: {
            fontSize: FontSizes.size14,
            margin: '0px',
            fontWeight: FontWeights.bold,
        },
        flexContainer: {
            alignItems: 'flexStart',
        },
    };
    const teachingBubbleStyles: Partial<ITeachingBubbleStyles> = {
        subText: {
            color: 'white',
        },
        headline: {
            color: 'white',
        },
        closeButton: {
            color: 'white',
        },
    };

    //need this because the onColumnClick function would only use the filterValue
    //from when it was instantiated
    const filterValueRef = useRef<string>();

    const onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn
    ): void => {
        // Sort the entire list, then reapply the filter
        const sortedEntries = columnSort(column, columns, props.list);
        setColumnsList(sortedEntries.newColumns);
        setSortedItems(sortedEntries.newItems);
        filterList(
            filterItems,
            setItems,
            sortedEntries.newItems,
            filterValueRef.current || ''
        );
    };

    const columns: IColumn[] = [
        {
            key: 'statusIndicator',
            name: '',
            minWidth: 20,
            maxWidth: 20,
        },
        {
            key: 'artifactId',
            name: 'Hydration Artifact Id',
            minWidth: 140,
            maxWidth: 300,
            isResizable: true,
            fieldName: 'artifactId',
            onColumnClick: onColumnClick,
        },
        {
            key: 'releaseCorrelationId',
            name: 'Release',
            minWidth: 120,
            maxWidth: 300,
            isResizable: true,
            fieldName: 'releaseCorrelationId',
            onColumnClick: onColumnClick,
        },
        {
            key: 'environmentId',
            name: 'Environment',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            fieldName: 'environmentId',
            onColumnClick: onColumnClick,
        },
        {
            key: 'totalFilesTransferred',
            name: 'Files Replicated',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            fieldName: 'totalFilesTransferred',
            onColumnClick: onColumnClick,
        },
        {
            key: 'totalBytesReplicated',
            name: 'Bytes Replicated',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            fieldName: 'totalBytesReplicated',
            onColumnClick: onColumnClick,
        },
        {
            key: 'createdOn',
            name: 'Created On',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'createdOn',
            onColumnClick: onColumnClick,
        },
    ];

    const renderArtifactColumn = (
        item: Artifact,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[
            column?.fieldName as keyof Artifact
        ] as string;

        let percentFileReduced =
            item.numReplicationPlanFiles < item.totalFilesTransferred
                ? 0
                : (
                      100 -
                      (item.totalFilesTransferred /
                          item.numReplicationPlanFiles) *
                          100
                  ).toFixed(1);

        let percentByteReduced =
            item.totalArtifactBytes < item.totalBytesReplicated
                ? 0
                : (
                      100 -
                      (item.totalBytesReplicated / item.totalArtifactBytes) *
                          100
                  ).toFixed(1);

        switch (column?.key) {
            case 'statusIndicator':
                return (
                    <CompletionIndicatorIcon
                        completionIndicator={item.completionIndicator}
                        completionIndicatorString={
                            item.completionIndicatorString
                        }
                        theme={theme}
                    />
                );
            case 'totalFilesTransferred':
                return (
                    <div>
                        <div>{item.totalFilesTransferred}</div>
                        <div
                            style={{
                                color: theme.palette.neutralTertiary,
                                fontSize: 11, //FontSizes.size12 looks too big
                            }}
                        >
                            {percentFileReduced + '% reduced'}
                        </div>
                    </div>
                );
            case 'totalBytesReplicated':
                return (
                    <div>
                        <div>
                            {convertToUserFriendlyByteString(
                                item.totalBytesReplicated
                            )}
                        </div>
                        <div
                            style={{
                                color: theme.palette.neutralTertiary,
                                fontSize: 11, //FontSizes.size12 looks too big
                            }}
                        >
                            {percentByteReduced + '% reduced'}
                        </div>
                    </div>
                );
            case 'createdOn':
                return dateLocaleStringWithTimeZone(item.createdOn);
            case 'artifactId':
                return (
                    <InternalLink
                        value={item.artifactId || ''}
                        url={
                            '#' +
                            getArtifactDetailsPath(
                                item.artifactId || ''
                                //item.serviceTreeId || '',
                                //props.serviceType
                            )
                        }
                        title="View artifact details"
                    />
                );
            case 'releaseCorrelationId':
                return (
                    <div>
                        {item.releaseCorrelationId && (
                            <InternalLink
                                value={
                                    item.releaseName
                                        ? item.releaseName
                                        : item.releaseCorrelationId
                                }
                                url={
                                    '#' +
                                    getReleaseDetailsPath(
                                        item.releaseCorrelationId
                                        //item.serviceTreeId || '',
                                        //props.serviceType
                                    )
                                }
                                title="View release details"
                            />
                        )}
                        {item.releaseId && (
                            <div
                                style={{
                                    color: theme.palette.neutralTertiary,
                                    fontSize: 11, //FontSizes.size12 looks too big
                                }}
                            >
                                {'Id: ' + item.releaseId}
                            </div>
                        )}
                    </div>
                );
            default:
                return <span>{fieldContent}</span>;
        }
    };

    //filter function
    const filterItems = (i: Artifact, newValue: string) => {
        return (
            (i.releaseId || '').toLowerCase().indexOf(newValue) > -1 ||
            (i.buildNumber || '').toLowerCase().indexOf(newValue) > -1 ||
            (i.environmentId || '').toLowerCase().indexOf(newValue) > -1 ||
            (i.currentStatus || '').toString().indexOf(newValue) > -1 ||
            (i.createdOn || '').toLocaleString().indexOf(newValue) > -1 ||
            (i.artifactId || '').toLowerCase().indexOf(newValue) > -1
        );
    };

    useEffect(() => {
        //set selection items when items list changes
        selection.setItems(
            items.map((artifact) => {
                return {
                    ...artifact,
                    key: artifact.artifactId,
                };
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        // Default sort by latest timestamp
        let artifacts = props.list;
        setColumnsList(columns);
        setItems(artifacts);
        setSortedItems(artifacts);
    }, [props.list, props.isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="ArtifactList-root">
            {props.showTitle && (
                <div ref={coachmarkTarget} style={{}}>
                    <Stack horizontal>
                        <Text variant="mediumPlus">Artifacts</Text>
                        <IconButton
                            iconProps={{ iconName: 'Help' }}
                            styles={helpButtonStyles}
                            onClick={showCoachmark}
                        />
                    </Stack>
                    {isCoachmarkVisible && (
                        <Coachmark
                            target={coachmarkTarget.current}
                            ariaAlertText="A coachmark has appeared to describe artifacts"
                            ariaDescribedBy="artifacts desciption"
                            ariaLabelledBy="artifacts desciption"
                            ariaDescribedByText="Press enter or alt + C to open the coachmark notification"
                            ariaLabelledByText="Coachmark notification"
                        >
                            <TeachingBubbleContent
                                headline="Artifacts"
                                hasCloseButton
                                closeButtonAriaLabel="Close"
                                onDismiss={hideCoachmark}
                                ariaDescribedBy="artifacts desciption"
                                ariaLabelledBy="artifacts desciption"
                                styles={teachingBubbleStyles}
                            >
                                Artifacts represent a collection of build files
                                that are replicated into air-gapped clouds.
                                Typically, if the same build is replicated
                                multiple times, each replication will have a
                                unique artifact. See{' '}
                                <a
                                    href="https://eng.ms/docs/cloud-ai-platform/azure-edge-platform-aep/aep-health-standards/core-platform-services/air-gap-platform/air-gap-synchronization-service/developer-docs/overview/overview#artifact"
                                    rel="noreferrer"
                                    target={'_blank'}
                                >
                                    here
                                </a>{' '}
                                for more information on Artifacts and AGSS as a
                                whole.
                            </TeachingBubbleContent>
                        </Coachmark>
                    )}
                </div>
            )}
            {props.showFilterBox && (
                <FilterBox
                    style={{ backgroundColor: props.backgroundColor }}
                    items={sortedItems}
                    setItems={setItems}
                    filterFunc={filterItems}
                    filterValueRef={filterValueRef}
                />
            )}
            <ShimmeredDetailsList
                setKey="single"
                className={commonGridStyle(theme, props.backgroundColor)}
                items={items}
                columns={columnsList}
                onRenderItemColumn={renderArtifactColumn}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={!props.isLoaded}
                shimmerLines={10}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={CheckboxVisibility.hidden}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Artifacts"
            />
            {props.isLoaded && !props.list.length && <p>No data found.</p>}
        </div>
    );
};
