import { Theme } from '@fluentui/react';

export type TimelineItem = {
    name: string;
    description: string;
    cloud: string;
    status: string;
    timestamp: string;
    isProjected?: boolean;
    isValid?: boolean;
    shouldDisplay: boolean;
};

export type CompletionStatusInfo = {
    name: string;
    iconName: string;
    iconColor?: string;
    tooltip?: string;
};

export const CompletionStatus: Record<string, CompletionStatusInfo> = {
    NotStarted: { name: 'Not Started', iconName: 'CircleRing' },
    Failed: { name: 'Failed', iconName: 'StatusErrorFull' },
    Cancelled: { name: 'Cancelled', iconName: 'BlockedSiteSolid12' },
    Processing: { name: 'Processing', iconName: 'Clock' },
    Inherited: {
        name: 'Completed',
        iconName: 'Completed',
        tooltip:
            'This status has been inherited from another replication of the same build or deployment, for the same cloud.',
    },
    Transferred: {
        name: 'Completed',
        iconName: 'Completed',
        tooltip:
            'This is the last status for a replication for a non-egressable cloud.',
    },
    Completed: { name: 'Completed', iconName: 'CompletedSolid' },
};

export const getStatusIconProperties = (
    status: string | undefined,
    theme: Theme
): CompletionStatusInfo => {
    let completionStatusInfo = CompletionStatus['NotStarted'];
    if (status && CompletionStatus[status]) {
        completionStatusInfo = CompletionStatus[status];
        switch (status) {
            case 'NotStarted':
                return {
                    ...completionStatusInfo,
                    iconColor: '',
                };
            case 'Failed':
                return {
                    ...completionStatusInfo,
                    iconColor: theme.semanticColors.errorIcon,
                };
            case 'Cancelled':
                return {
                    ...completionStatusInfo,
                    iconColor: theme.semanticColors.warningHighlight,
                };
            case 'Processing':
                return {
                    ...completionStatusInfo,
                    iconColor: theme.palette.themePrimary,
                };
            case 'Inherited':
                return {
                    ...completionStatusInfo,
                    iconColor: theme.semanticColors.successIcon,
                };
            case 'Transferred':
                return {
                    ...completionStatusInfo,
                    iconColor: theme.semanticColors.successIcon,
                };
            case 'Completed':
                return {
                    ...completionStatusInfo,
                    iconColor: theme.semanticColors.successIcon,
                };
        }
    }
    return completionStatusInfo;
};

export type CompletionStatusTracker = {
    currentStep: number;
    totalSteps: number;
};
