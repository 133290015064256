import React from 'react';
import { IStackTokens, Stack, Text } from '@fluentui/react';
import { TimelineItem } from '../../../../models/Timeline';
import { FlightDataDisplay } from './FlightDataDisplay';

interface ICurrentReplicationTimeline {
    currentFlightTimeline: TimelineItem[] | undefined;
}

export const CurrentReplicationTimeline: React.FC<ICurrentReplicationTimeline> =
    (props: ICurrentReplicationTimeline) => {
        const stackTokens: IStackTokens = { childrenGap: 10 };
        const sectionStackTokens: IStackTokens = { childrenGap: 20 };

        return (
            <div className="CurrentReplicationTimeline-root">
                <Stack tokens={sectionStackTokens}>
                    <Text variant={'mediumPlus'}>Timeline</Text>
                    <Stack tokens={stackTokens}>
                        {props.currentFlightTimeline &&
                            props.currentFlightTimeline.length > 0 &&
                            props.currentFlightTimeline.map((marker) =>
                                marker.shouldDisplay ? (
                                    <FlightDataDisplay
                                        key={marker.name}
                                        name={marker.name}
                                        cloud={marker.cloud}
                                        description={marker.description}
                                        timestamp={marker.timestamp}
                                        status={marker.status}
                                        isProjected={marker.isProjected}
                                        isValid={marker.isValid}
                                        shouldDisplay={marker.shouldDisplay}
                                    />
                                ) : (
                                    <></>
                                )
                            )}
                    </Stack>
                </Stack>
            </div>
        );
    };
