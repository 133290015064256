import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CancelToken } from 'axios';
import { usePromise } from '../../../Common/hooks/usePromise';
import { ErrorPage } from '../../../HubLayout/components/ErrorPage';
import { PageHeader } from '../../../HubLayout/components/PageLayout/PageHeader';
import { ReleaseFlow } from './ReleaseFlow';
import { ServiceType } from '../../../ABSM/models/ServiceType';
import {
    getNewYamlReleaseUrl,
    getAdoAccountUrl,
    getAdoProjectUrl,
    getServiceTreeUrl,
    ServiceReleaseDetailsRouteParams,
} from '../../../Common/util/RouteUtils';
import { useParams } from 'react-router-dom';
import { useAsState } from '../../../Common/hooks/useAsState';
import { getReleaseDetails } from '../../api/AGSSApi';
import { CompletionStatusDisplay } from './Display/Status/CompletionStatusDisplay';
import { IStackTokens, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { PageBreadcrumbTrail } from '../../../HubLayout/components/PageLayout/PageBreadcrumbTrail';
import { PageType } from '../../../HubLayout/models/Page';
import { LinkItem } from '../../../HubLayout/models/LinkItem';
import { spinnerStyles, stackStyles } from '../../util/StyleUtils';
import useDocumentTitle from '../../../Common/hooks/useDocumentTitle';
import { ReleasePipelineSource } from '../../models/ReleasePipelineSource';
import { emitPageLoadMetric } from '../../../Common/util/metricsUtil';
import { Capability } from '../../../Common/components/Capabilities/capability';
import { CapabilityContext } from '../../../Common/components/Capabilities/CapabilityContext';
import { BackButton } from '../../../HubLayout/components/Utilities/BackButton';
import { ReleaseExporter } from '../../../Administration/components/Importer/ReleaseExporter';

export interface IReleaseDetailsProps {
    serviceType?: ServiceType;
}

export const ReleaseDetailsPage: React.FC<IReleaseDetailsProps> = (
    props: IReleaseDetailsProps
) => {
    const capabilities = useContext(CapabilityContext);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const routeParams = useParams<ServiceReleaseDetailsRouteParams>();
    const params = useAsState<ServiceReleaseDetailsRouteParams>(routeParams); // fixes timing bug on routeParam change

    const getData = useCallback(
        async (cancelToken?: CancelToken) => {
            if (params.releaseCorrelationId) {
                //query for specify releaseCorrelationId
                return await getReleaseDetails(
                    params.releaseCorrelationId,
                    cancelToken
                );
            }
            return undefined;
        },
        // When dependency array changes, usePromise will clear data and restart API.
        [params.releaseCorrelationId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const [data, error, isLoaded] = usePromise(getData, true);
    const release = data;

    const releasePipelineSource = (): string => {
        if (release) {
            if (
                release.releasePipelineSource === ReleasePipelineSource.Classic
            ) {
                return 'Classic';
            } else if (
                release.releasePipelineSource === ReleasePipelineSource.YAML
            ) {
                return 'YAML';
            }
        }
        return '';
    };

    // Tokens definition
    const stackTokens: IStackTokens = { childrenGap: 10 };

    // Style definitions
    const midStackStyle = {
        display: 'flex',
        alignItems: 'center',
        marginTop: '-5px',
        marginLeft: '-2px',
    };

    const bottomStackStyle = {
        marginTop: '-15px',
        marginLeft: '-10px',
    };

    const releaseButtonDiv = {
        scale: '70%',
        marginTop: '7px',
        marginLeft: '-10px',
    };

    const backButtonStyle = {
        scale: '70%',
    };

    const noLeftMargin = {
        marginLeft: '0px',
    };

    const releaseFlowStyle = {
        marginTop: '-10px',
    };

    const getTitle = (): string => {
        if (release) {
            if (release.releaseName) {
                return release.releaseName + ' (Id: ' + release.releaseId + ')';
            }
            return 'Release Details (Id: ' + release.releaseId + ')';
        }
        return 'Release Details';
    };

    useDocumentTitle(getTitle());

    const getAdoReleaseItem = (): LinkItem[] => {
        const releaseItem: LinkItem = {
            text: release?.releaseId?.toString() || '',
            href: release?.releaseWebUrl || '',
            iconName: PageType.Release,
            title: 'Open Azure DevOps Release Pipeline',
        };
        return [releaseItem];
    };

    const getNewYAMLLink = (): LinkItem[] => {
        const releaseItem: LinkItem = {
            text: release?.releaseId?.toString() || '',
            href: getNewYamlReleaseUrl(
                release?.accountName || release?.adoInstance || '',
                release?.projectName || '',
                '',
                release?.releaseId || 0
            ),
            iconName: PageType.Release,
            title: 'Open Azure DevOps Release Pipeline',
        };
        return [releaseItem];
    };

    const getServiceItem = (): LinkItem[] => {
        const serviceItem: LinkItem = {
            text: release?.serviceTreeName || 'Service',
            href: getServiceTreeUrl(
                release?.serviceTreeId || '',
                release?.serviceTreeLevel || 'Service'
            ),
            iconName: PageType.Service,
            title: 'Open Service Tree profile',
        };
        return [serviceItem];
    };

    const getAccountProjectItems = (): LinkItem[] => {
        const accountItem: LinkItem = {
            text: release?.adoInstance || 'Account',
            href: getAdoAccountUrl(release?.adoInstance || ''),
            iconName: PageType.Account,
            title: 'Open Azure DevOps Account',
        };
        const projectItem: LinkItem = {
            text: release?.projectName || 'Project',
            href: getAdoProjectUrl(
                release?.accountName || release?.adoInstance || '',
                release?.projectName || '',
                ''
            ),
            iconName: PageType.Project,
            title: 'Open Azure DevOps Project',
        };
        return [accountItem, projectItem];
    };

    // metric to measure inital page load time
    useEffect(() => {
        var emittedSuccessfully = emitPageLoadMetric(
            'ReleaseDetails',
            process.env.REACT_APP_BUILD_VERSION,
            isFirstLoad
        );

        setIsFirstLoad(emittedSuccessfully);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return error ? (
        <ErrorPage error={error} />
    ) : (
        <div className="ReleaseDetailsPage-root">
            <PageHeader title={getTitle()} />
            {isLoaded && (
                <Stack tokens={stackTokens}>
                    <Stack
                        tokens={stackTokens}
                        horizontal
                        style={midStackStyle}
                    >
                        {/* Show overall release status */}
                        {release && release.completionIndicator && (
                            <CompletionStatusDisplay
                                completionIndicator={
                                    release.completionIndicator
                                }
                                completionStatusTracker={
                                    release.completionStatusTracker
                                }
                                addPadding={true}
                            />
                        )}

                        {capabilities.check(Capability.srmAdminRole) && (
                            <div style={releaseButtonDiv}>
                                <ReleaseExporter
                                    releaseCorrelationIds={[
                                        params.releaseCorrelationId,
                                    ]}
                                />
                            </div>
                        )}
                    </Stack>
                    <Stack
                        horizontal
                        tokens={stackTokens}
                        className={stackStyles.centeredStack}
                        style={bottomStackStyle}
                    >
                        <div style={backButtonStyle}>
                            <BackButton />
                        </div>
                        {release?.releaseId && (
                            <Stack horizontal style={noLeftMargin}>
                                <PageBreadcrumbTrail
                                    inputItems={
                                        release.releasePipelineSource !==
                                        ReleasePipelineSource.YAML
                                            ? getAdoReleaseItem()
                                            : getNewYAMLLink()
                                    }
                                    label={
                                        releasePipelineSource() + ' ADO Release'
                                    }
                                />
                                <PageBreadcrumbTrail
                                    inputItems={[
                                        {
                                            text: '|',
                                            href: '',
                                            iconName: '',
                                        },
                                    ]}
                                    label=""
                                />
                            </Stack>
                        )}

                        <PageBreadcrumbTrail
                            inputItems={getServiceItem()}
                            label="Service Tree"
                        />
                        <PageBreadcrumbTrail
                            inputItems={[
                                {
                                    text: '|',
                                    href: '',
                                    iconName: '',
                                },
                            ]}
                            label=""
                        />
                        <PageBreadcrumbTrail
                            inputItems={getAccountProjectItems()}
                            label="ADO Hierarchy"
                        />
                    </Stack>
                    <div style={releaseFlowStyle}>
                        {/* There will be one of these per flow */}
                        <ReleaseFlow
                            release={release}
                            isLoaded={isLoaded}
                            //serviceType={props.serviceType}
                            //serviceId={params.serviceId}
                        />
                    </div>
                </Stack>
            )}
            {!isLoaded && (
                <div>
                    <Spinner styles={spinnerStyles} size={SpinnerSize.large} />
                </div>
            )}
        </div>
    );
};
