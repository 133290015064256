import { Guid } from '../../Common/models/Guid';
import { Deployment } from '../models/Deployment';
import { TimelineItem } from '../models/Timeline';
import { ServiceType } from '../../ABSM/models/ServiceType';
import { ReleaseSearchResult } from '../models/Release';
import { ReplicationStatusResult } from '../models/ReplicationStatusResult';
import { ICapabilityContext } from '../../Common/components/Capabilities/CapabilityContext';
import { Replication } from '../models/Replication';
import { CloudEnvironment } from '../../Common/models/CloudEnvironment';
import { Capability } from '../../Common/components/Capabilities/capability';
import { Timestamp } from '../../Common/models/Timestamp';
import { dateLocaleStringWithTimeZone } from '../../Common/util/DateUtils';

export const sortReleaseListDescending = (
    releases: ReleaseSearchResult[]
): ReleaseSearchResult[] => {
    return releases.sort((s1, s2) => {
        return new Date(s1.updatedOn || '') > new Date(s2.updatedOn || '')
            ? -1
            : 1;
    });
};

//For service-oriented Hub
export const getReleasePath = (
    releaseCorrelationId: string,
    serviceId: Guid,
    serviceType: ServiceType
): string => {
    const releaseUrl = `${serviceId}/Releases/${releaseCorrelationId}`;
    switch (serviceType) {
        case 'Component':
            return `/Components/${releaseUrl}`;
        case 'Service':
            return `/Services/${releaseUrl}`;
        case 'Organization':
            return `/Organizations/${releaseUrl}`;
        case 'Division':
            return `/Divisions/${releaseUrl}`;
    }
};

export const getReleaseDetailsPath = (releaseCorrelationId: string): string => {
    return `/ReleaseStatus/Release/${releaseCorrelationId}`;
};

export const getCurrentReplicationTimeline = (
    item: Replication | undefined
): TimelineItem[] => {
    if (item) {
        let statusList = item.statusList;
        let sortedList = sortStatusAscending(statusList);

        return sortedList.map((replicationStatusResult) => {
            let timestamp = replicationStatusResult?.isProjected
                ? replicationStatusResult?.projectedDate
                : replicationStatusResult?.updatedOn;
            return {
                name: replicationStatusResult?.name,
                description: replicationStatusResult?.description || '',
                cloud: replicationStatusResult?.environmentId || '',
                status: replicationStatusResult?.completionIndicator
                    ? replicationStatusResult?.completionIndicator
                    : getStepStatus(item.lastUpdated, timestamp),
                timestamp: dateLocaleStringWithTimeZone(timestamp),
                isProjected: replicationStatusResult?.isProjected,
                isValid: replicationStatusResult?.isValid,
                shouldDisplay: replicationStatusResult?.shouldDisplay,
            };
        });
    }
    return [];
};

export const getCurrentDeploymentTimeline = (
    item: Deployment
): TimelineItem[] => {
    return getCurrentReplicationTimeline(item.currentReplication);
};

//check if time happened or is estimated future date
export const getStepStatus = (
    replicationTimestamp: Timestamp,
    statusTimestamp: Timestamp | undefined
): string => {
    if (statusTimestamp) {
        if (
            new Date(replicationTimestamp).getTime() >
            new Date(statusTimestamp).getTime()
        ) {
            return 'Completed';
        }
        if (
            new Date(replicationTimestamp).getTime() ===
            new Date(statusTimestamp).getTime()
        ) {
            return 'Processing';
        }
    }
    return 'NotStarted';
};

export const sortReplicationsDescending = (
    replications: Replication[]
): Replication[] => {
    return replications.sort((s1, s2) => {
        return new Date(s1.lastUpdated) > new Date(s2.lastUpdated) ? -1 : 1;
    });
};

export const isTentedForReplication = (
    capabilities: ICapabilityContext,
    replication: Replication
): boolean => {
    switch (replication.environmentId) {
        case CloudEnvironment.USSec:
            return capabilities.check(Capability.rx_tent);
        case CloudEnvironment.USNat:
            return capabilities.check(Capability.ex_tent);
        case CloudEnvironment.Public:
            return true;
        default:
            return false;
    }
};

export const sortStatusAscending = (
    statusList: ReplicationStatusResult[]
): ReplicationStatusResult[] => {
    return statusList.sort((s1, s2) => {
        return s1.index > s2.index ? 1 : -1;
    });
};
