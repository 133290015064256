import {
    IImageProps,
    IStackTokens,
    Image,
    PrimaryButton,
    Stack,
    Text,
} from '@fluentui/react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { linkStyle } from '../../../Common/util/CommonStyles';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../models/ThemeContext';
import { routeKeys } from '../../../Common/util/RouteUtils';

interface IHomeHeroSectionProps {}

export const HomeHeroSection: React.FC<IHomeHeroSectionProps> = (
    props: IHomeHeroSectionProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const sectionStackTokens: IStackTokens = { childrenGap: 10 };

    const imageProps: Partial<IImageProps> = {
        src: process.env.PUBLIC_URL + '/abLogo_cropped.svg',
    };

    return (
        <Stack horizontalAlign="center" verticalAlign="center" horizontal>
            <Image
                {...imageProps}
                alt="Azure Bridge Hub"
                width={200}
                height={200}
            />

            <Stack
                tokens={sectionStackTokens}
                style={{
                    width: '25%',
                }}
            >
                <Text variant="xxLarge">Azure Bridge Hub</Text>
                <Text variant="small">
                    Providing a comprehensive platform for tracking releases and
                    deployments. We strive to empower teams with the tools they
                    need to streamline their release processes, increase
                    visibility and collaboration, and ultimately deliver
                    high-quality software to their customers faster and more
                    efficiently.
                </Text>
                <PrimaryButton>
                    <Link
                        to={routeKeys.Features.Releases.Latest}
                        className={'link ' + linkStyle(theme)}
                    >
                        See All Releases
                    </Link>
                </PrimaryButton>
            </Stack>
        </Stack>
    );
};
