import { IconButton } from '@fluentui/react';
import React, { useContext } from 'react';
import { Deployment, DeploymentMetadataItem } from '../../../models/Deployment';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';

interface IDeploymentMetadataProps {
    item: Deployment;
}

export const DeploymentMetadata: React.FC<IDeploymentMetadataProps> = (
    props: IDeploymentMetadataProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const metadataFieldNameStyles = {
        width: '10%',
        paddingRight: '5px',
    };

    const metadataValueStyles = {
        display: 'flex',
        width: '90%',
        border: '1px gray solid',
        paddingLeft: '5px',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const metadataContainerStyles = {
        display: 'flex',
        paddingLeft: '16px',
        paddingBottom: '5px',
        paddingTop: '10px',
        alignItems: 'center',
    };

    const copyToClipboard = (metadata: DeploymentMetadataItem) => {
        navigator.clipboard.writeText(metadata.value);
    };

    return props.item.showMetadata ? (
        <div
            style={{
                backgroundColor: theme.palette.neutralLighter,
                paddingLeft: '40px',
            }}
        >
            {Array.from(props.item.deploymentMetadataItems.values()).map(
                (metadata) => {
                    return metadata?.value?.length > 0 ? (
                        <div
                            key={metadata.fieldName}
                            style={metadataContainerStyles}
                        >
                            <strong style={metadataFieldNameStyles}>
                                {metadata.fieldName}
                            </strong>
                            <div style={metadataValueStyles}>
                                <div>{metadata.value}</div>
                                <IconButton
                                    iconProps={{ iconName: 'Copy' }}
                                    onClick={() => copyToClipboard(metadata)}
                                    title={'Copy to clipboard'}
                                />
                            </div>
                        </div>
                    ) : (
                        <div key={metadata?.fieldName}></div>
                    );
                }
            )}
        </div>
    ) : (
        <div></div>
    );
};
