import React, { useContext } from 'react';
import {
    DefaultEffects,
    Stack,
    Pivot,
    PivotItem,
    IconButton,
    IIconProps,
    FontWeights,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { PageBrand } from './PageBrand';
import { routeKeys } from '../../../Common/util/RouteUtils';
import { useLocation, useHistory } from 'react-router-dom';
import { UserProfile } from '../UserPages/UserProfile';
import { Search } from '../Search/Search';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

interface ISiteHeaderProps {
    showSearch?: boolean;
}

export const SiteHeader: React.FC<ISiteHeaderProps> = (
    props: ISiteHeaderProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const location = useLocation();
    const history = useHistory();
    const mailIcon: IIconProps = {
        iconName: 'NewMail',
        styles: { root: { fontWeight: FontWeights.bold } },
    };


    let homeItem = (
        <PivotItem
            headerText="Azure Bridge"
            itemKey={routeKeys.Home}
            onRenderItemLink={() => {
                return <PageBrand />;
            }}
        />
    );

    return (
        <header
            className="SiteHeader-root"
            style={{
                boxShadow: DefaultEffects.elevation8,
                backgroundColor: theme.palette.white,
                position: 'relative',
                zIndex: 9999,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        marginRight: 'auto',
                    }}
                >
                    <Stack horizontal>
                        <Pivot
                            headersOnly={true}
                            selectedKey={null}
                            onLinkClick={(
                                item?: PivotItem,
                                ev?: React.MouseEvent<HTMLElement, MouseEvent>
                            ) => {
                                const itemKey = item
                                    ? item.props.itemKey || ''
                                    : '';
                                if (location.pathname !== itemKey) {
                                    history.push(itemKey);
                                }
                            }}
                        >
                            {homeItem}
                        </Pivot>
                    </Stack>
                </div>

                {props.showSearch && <Search />}

                <div
                    style={{
                        margin: '2px 3px 0 2px',
                        paddingTop: '5px',
                    }}
                >
                    <IconButton
                        iconProps={mailIcon}
                        title='For help, click here to email the "Azure Bridge Web Team" distribution list.'
                        href="mailto:azure-bridge-web@microsoft.com"
                        ariaLabel="Help"
                    />
                </div>

                <div
                    style={{
                        margin: '2px 3px 0 2px',
                        paddingTop: '5px',
                    }}
                >
                    <IconButton
                        iconProps={{ iconName: 'Documentation' }}
                        title="For information on the Azure Bridge Hub, click here."
                        href="https://eng.ms/docs/cloud-ai-platform/azure-edge-platform-aep/aep-silver/sovereign-platform-team/sovereign-release-manager/customer-docs"
                        target="_blank"
                        ariaLabel="Help"
                    />
                </div>

                <div
                    style={{
                        margin: '2px 3px 0 2px',
                        paddingTop: '5px',
                    }}
                >
                    <IconButton
                        iconProps={{ iconName: 'DocumentSet' }}
                        title="For information on SRM Kusto, click here."
                        href="https://eng.ms/docs/cloud-ai-platform/azure-edge-platform-aep/aep-silver/sovereign-platform-team/sovereign-release-manager/customer-docs/onboarding/kusto/kustoonboarding"
                        target="_blank"
                        ariaLabel="Help"
                    />
                </div>

                <div
                    style={{
                        marginTop: '2px',
                        marginRight: '5px',
                    }}
                >
                    <UserProfile />
                </div>
            </div>
        </header>
    );
};
