import React, { useContext } from 'react';
import { Icon, Stack, Text } from '@fluentui/react';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { TextWithTooltip } from '../../../../../Common/components/TextWithTooltip';
import { getStatusIconProperties } from '../../../../models/Timeline';

interface IFlightDataDisplay {
    name: string;
    cloud: string;
    description: string;
    timestamp: string;
    status: string;
    isProjected?: boolean;
    isValid?: boolean;
    shouldDisplay: boolean;
}

export const FlightDataDisplay: React.FC<IFlightDataDisplay> = (
    props: IFlightDataDisplay
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    return (
        <div className="FlightDataDisplay-root">
            {props.shouldDisplay && (
                <Stack horizontal verticalAlign="center">
                    <Icon
                        style={{
                            paddingRight: '10px',
                            color: getStatusIconProperties(props.status, theme)
                                .iconColor,
                        }}
                        iconName={
                            getStatusIconProperties(props.status, theme)
                                .iconName
                        }
                        title={props.status}
                        ariaLabel={'Status icon'}
                    />
                    <Stack>
                        <TextWithTooltip
                            tooltip={props.description}
                            data={
                                props.name +
                                (props.cloud ? ' (' + props.cloud + ')' : '')
                            }
                        />
                        <Text variant="medium">
                            {props.isProjected
                                ? 'Projected: ' + (props.timestamp || 'Unknown')
                                : props.timestamp}
                        </Text>
                    </Stack>
                </Stack>
            )}
        </div>
    );
};
