export enum PageType {
    Release = 'Rocket',
    Account = 'WorkforceManagement',
    Project = 'ProjectCollection',
    Service = 'AllApps',
    Metrics = 'AnalyticsView',
    Egress = 'OpenFile',
    Search = 'Search',
    ArtifactList = 'Archive',
    Artifact = 'PreviewLink',
    Default = 'Page',
    Division = 'Org',
    Organization = 'WorkforceManagement',
    Feature = 'Product',
    Component = 'ContextMenu',
    Build = 'WebComponents',
    Repo = 'Repo',
    Deployment = 'Deploy',
    Correlation = 'BranchCompare',
}

interface PageInfo {
    type: PageType;
    name: string;
}

export const PageLookup = new Map<string, PageInfo>([
    [
        '/Divisions',
        {
            name: 'Divisions',
            type: PageType.Division,
        },
    ],
    [
        '/Organizations',
        {
            name: 'Organizations',
            type: PageType.Organization,
        },
    ],
    [
        '/Services',
        {
            name: 'Services',
            type: PageType.Service,
        },
    ],
    [
        '/Features',
        {
            name: 'Features',
            type: PageType.Feature,
        },
    ],
    [
        '/ReleaseStatus',
        {
            name: 'Releases',
            type: PageType.Release,
        },
    ],
    [
        '/ReleaseStatus/Latest',
        {
            name: 'Latest Releases',
            type: PageType.Release,
        },
    ],

    [
        '/LegacyReleaseStatus',
        {
            name: 'Legacy Releases',
            type: PageType.Release,
        },
    ],
    [
        '/LegacyReleaseStatus/Latest',
        {
            name: 'Latest Legacy Releases',
            type: PageType.Release,
        },
    ],
    [
        '/ReleaseStatus/Release/Search',
        {
            name: 'Release Status (Search Results)',
            type: PageType.Search,
        },
    ],
]);
