import { agpAxiosClient } from '../../Common/api/ABHub/ABHubTokenConfig';
import {
    Version,
    VersionResponse,
    AGCPPublishResponse,
} from '../models/AirGapMessage';
import { CancelToken } from 'axios';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../Common/api/ABHub/ABHubGlobalConfig';

export const getVersion = async (
    cancelToken?: CancelToken
): Promise<Version[]> => {
    const agssUrl = getConfig(GlobalConfigProperties.AgssUrl);
    const response = await agpAxiosClient.get<VersionResponse>(
        agssUrl + '/api/version',
        { cancelToken }
    );

    return response.data.versions;
};

export const publishCommand = async (
    message: any,
    eventType: string,
    environment: string,
    cancelToken?: CancelToken
): Promise<AGCPPublishResponse<boolean>> => {
    const response = await agpAxiosClient.post(
        `/API/publish/${eventType}/${environment}`,
        message,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return response.data;
};
