import React, {
    PropsWithChildren,
    useEffect,
    useState,
    useCallback,
    FormEvent,
    useContext,
    SetStateAction,
    Dispatch,
    CSSProperties,
} from 'react';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IComboBoxStyles,
    SelectableOptionMenuItemType,
} from '@fluentui/react';
import { getServicesList } from '../../AGSS/api/AGSSApi';
import { CancelToken } from 'axios';
import { usePromise } from '../hooks/usePromise';
import { ThemeContext } from '../../HubLayout/models/ThemeContext';
import {
    getSelectedServiceNamesFromLocalStorage,
    getThemeFromString,
    setSelectedServiceNamesInLocalStorage,
} from '../util/localStorageUtils';
import { emitUserActionMetric } from '../util/metricsUtil';
import {
    ActionResult,
    FilterTypes,
    UserAction,
} from '../../Common/Enums/Metrics/MetricEnums';

interface IServiceNameDropDownProps extends PropsWithChildren<any> {
    setSelectedServices?: Dispatch<SetStateAction<string[]>>;
    setPageNumber?: Dispatch<SetStateAction<number>>;
    isLoaded: boolean;
}

export const ServiceNameDropDown: React.FC<IServiceNameDropDownProps> = (
    props: IServiceNameDropDownProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [options, setOptions] = useState<IComboBoxOption[]>([]);
    const [dict, ,] = useState<Map<string, string[]>>(
        new Map<string, string[]>()
    );

    const getData = useCallback(async (cancelToken?: CancelToken) => {
        return await getServicesList(cancelToken);
    }, []);

    const [result, , dropdownLoaded] = usePromise(getData, true);

    useEffect(() => {
        if (dropdownLoaded && result) {
            let comboboxOptions: IComboBoxOption[] = [
                {
                    key: 'selectAll',
                    text: 'Select All',
                    itemType: SelectableOptionMenuItemType.SelectAll,
                },
                {
                    key: 'Header1',
                    text: 'Service Names',
                    itemType: SelectableOptionMenuItemType.Header,
                },
            ];

            result.forEach((service) => {
                if (!service.name) {
                    return undefined;
                }

                if (dict.get(service.name)) {
                    dict.get(service.name)?.push(service.id);
                } else {
                    dict.set(service.name, [service.id]);
                }
            });

            dict.forEach((value, name) => {
                var serviceOption = {
                    key: value.toString(),
                    text: name,
                } as IComboBoxOption;

                var alreadyExists = comboboxOptions.some(function (option) {
                    return option.text === name;
                });

                if (!alreadyExists) {
                    comboboxOptions.push(serviceOption);
                }
            });

            setOptions(comboboxOptions);
        }
    }, [result, dropdownLoaded, dict]);

    const divStyles: CSSProperties = {
        display: 'contents',
        margin: '6px',
    };

    const comboBoxStyles: Partial<IComboBoxStyles> = {
        root: {
            maxWidth: 300,
            selectors: {
                ':after': {
                    border: 'none',
                },
            },
            backgroundColor: theme.palette.neutralLighter,
        },
        container: { backgroundColor: theme.palette.neutralLighter },
        callout: { maxHeight: 300 },
        optionsContainerWrapper: { maxHeight: 300 },
        optionsContainer: {
            backgroundColor: theme.palette.neutralLighter,
        },
        input: {
            backgroundColor: theme.palette.neutralLighter,
        },
    };

    const selectableOptions = options.filter(
        (option) =>
            (option.itemType === SelectableOptionMenuItemType.Normal ||
                option.itemType === undefined) &&
            !option.disabled
    );

    const sortOptions = () => {
        const selectableOptions = options.filter(
            (option) =>
                (option.itemType === SelectableOptionMenuItemType.Normal ||
                    option.itemType === undefined) &&
                !option.disabled
        );

        const opts = selectableOptions.filter(
            (option) => option.key !== 'selectAll'
        );

        const selected = opts.filter((o) =>
            selectedKeys.includes(o.key as string)
        );
        const unselected = opts.filter(
            (o) => !selectedKeys.includes(o.key as string)
        );

        setOptions([
            {
                key: 'selectAll',
                text: 'Select All',
                itemType: SelectableOptionMenuItemType.SelectAll,
            },
            {
                key: 'Header1',
                text: 'Service Names',
                itemType: SelectableOptionMenuItemType.Header,
            },
            ...selected.concat(unselected),
        ]);
    };

    const onChange = (
        event: FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string
    ) => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeys.filter(
            (key) => key !== 'selectAll'
        );
        const selectAllState =
            currentSelectedOptionKeys.length === selectableOptions.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                if (selectAllState) {
                    setSelectedKeys([]);
                    setSelectedServiceNamesInLocalStorage([]);
                } else {
                    setSelectedKeys([
                        'selectAll',
                        ...selectableOptions.map((o) => o.key as string),
                    ]);
                    setSelectedServiceNamesInLocalStorage([
                        'selectAll',
                        ...selectableOptions.map((o) => o.key as string),
                    ]);
                }
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter((k) => k !== option.key);
                if (updatedKeys.length === selectableOptions.length) {
                    updatedKeys.push('selectAll');
                }
                setSelectedKeys(updatedKeys);
                setSelectedServiceNamesInLocalStorage(updatedKeys);
            }
        }

        emitUserActionMetric(
            UserAction.DROPDOWN_FILTER,
            FilterTypes.SERVICE_NAME,
            ActionResult.SUCCESS,
            process.env.REACT_APP_BUILD_VERSION
        );
    };

    useEffect(() => {
        const selectedKeysFromStorage =
            getSelectedServiceNamesFromLocalStorage();

        if (selectedKeysFromStorage) {
            selectedKeysFromStorage.forEach((key) => {
                if (!selectedKeys.includes(key)) {
                    selectedKeys.push(key);
                }
            });
        }

        const currentSelectedOptionKeys = selectedKeys.filter(
            (key) => key !== 'selectAll'
        );

        if (props.setSelectedServices && props.setPageNumber) {
            if (selectedKeys.length === options.length - 1) {
                setSelectedServiceNamesInLocalStorage([]);
                props.setSelectedServices([]);
                props.setPageNumber(1);
            } else {
                props.setSelectedServices(currentSelectedOptionKeys);
                props.setPageNumber(1);
            }
        }

        sortOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedKeys, props.setSelectedServices]);

    useEffect(() => {
        setDisabled(!props.isLoaded);
        let comboboxOptions = options.map((option) => {
            return {
                ...option,
                disabled: props.isLoaded ? false : true,
            };
        });
        setOptions(comboboxOptions);
        sortOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoaded]);

    return (
        <div style={divStyles}>
            {result && result.length > 0 && (
                <ComboBox
                    multiSelect
                    allowFreeform
                    placeholder="Service Name"
                    options={options}
                    selectedKey={selectedKeys}
                    onChange={onChange}
                    styles={comboBoxStyles}
                    disabled={disabled}
                />
            )}
        </div>
    );
};
