import * as React from 'react';
import {
    Dropdown,
    IDropdownOption,
    ISelectableOption,
    IStackTokens,
    Icon,
    Panel,
    PanelType,
    Stack,
    Text,
    Link,
} from '@fluentui/react';
import { useContext, useEffect, useState } from 'react';
import { TimelineItem } from '../../../models/Timeline';
import { ServiceType } from '../../../../ABSM/models/ServiceType';
import { Guid } from '../../../../Common/models/Guid';
import { Deployment } from '../../../models/Deployment';
import { getCurrentReplicationTimeline } from '../../../util/ReleaseUtils';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { CurrentReplicationTimeline } from './Status/CurrentReplicationTimeline';
import { CompletionIndicatorIcon } from './Status/CompletionIndicatorIcon';
import { DetailsPanelTextBox } from './DetailsPanelTextBox';
import { Build } from '../../../models/Build';
import { getBuildDetailsPath } from '../../../util/BuildUtils';
import { linkStyle } from '../../../../Common/util/CommonStyles';

interface IDetailsPanelProps {
    isOpen: boolean;
    dismissPanel:
        | ((
              ev?:
                  | React.SyntheticEvent<HTMLElement, Event>
                  | KeyboardEvent
                  | undefined
          ) => void)
        | undefined;
    selected: Deployment | Build | undefined;
    isLoaded: boolean;
    serviceType?: ServiceType;
    serviceId?: Guid;
}

export const DetailsPanel: React.FC<IDetailsPanelProps> = (
    props: IDetailsPanelProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const [selected, setSelected] = useState<Deployment | Build>();
    const [currentFlightTimeline, setCurrentFlightTimeline] =
        useState<TimelineItem[]>();
    const [options, setOptions] = useState<IDropdownOption[]>([]);
    const [show, setShow] = useState<Boolean>(false);
    const [lastSelectedOption, setLastSelectedOption] =
        useState<IDropdownOption>();

    // Tokens definition
    const stackTokens: IStackTokens = { childrenGap: 10 };

    const onRenderOption = (
        props: ISelectableOption<any> | undefined
    ): JSX.Element => {
        return (
            <div
                style={{
                    display: 'flex',
                }}
            >
                {props?.data && props.data.icon && (
                    <CompletionIndicatorIcon
                        theme={theme}
                        completionIndicator={props.data.icon}
                        completionIndicatorString={props.data.icon}
                    />
                )}
                <span
                    style={{
                        paddingLeft: '8px',
                    }}
                >
                    {props?.text}
                </span>
            </div>
        );
    };

    const onRenderTitle = (
        props: ISelectableOption<any>[] | undefined
    ): JSX.Element => {
        var option = undefined;

        if (props) {
            option = props[0];
        }

        return (
            <div style={{ display: 'flex' }}>
                {option?.data && option.data.icon && (
                    <CompletionIndicatorIcon
                        theme={theme}
                        completionIndicator={option.data.icon}
                        completionIndicatorString={option.data.icon}
                    />
                )}
                <span
                    style={{
                        paddingLeft: '8px',
                    }}
                >
                    {option?.text}
                </span>
            </div>
        );
    };

    // When parent component sends new data, refresh my state accordingly
    useEffect(() => {
        if (props.selected) {
            setSelected(props.selected);
            setCurrentFlightTimeline(
                getCurrentReplicationTimeline(props.selected.currentReplication)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selected]);

    useEffect(() => {
        const opts: IDropdownOption[] = [];
        // Order replications by latest
        if (selected) {
            const repList = selected.replications?.sort((r1, r2) => {
                if (r1.createdOn && r2.createdOn) {
                    return Date.parse(r2.createdOn) - Date.parse(r1.createdOn);
                }
                return 0;
            });

            repList?.forEach((rep, index) => {
                const opt: IDropdownOption = {
                    key: `attempt${repList.length - index}`,
                    text: `Attempt #${repList.length - index}`,
                    data: {
                        icon: rep.completionIndicator,
                        replication: rep,
                    },
                };
                opts.push(opt);
            });

            setOptions(opts);

            if (opts.length > 0) {
                setCurrentFlightTimeline(
                    getCurrentReplicationTimeline(opts[0].data.replication)
                );
            }

            setShow(true);
        }
    }, [selected]);

    const onSelectAttempt = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number
    ) => {
        if (option && option.data) {
            setCurrentFlightTimeline(
                getCurrentReplicationTimeline(option.data.replication)
            );
            setLastSelectedOption(option);
            setShow(true);
        }
    };

    // JavaScript/TypeScript's weird way of type checking
    const isDeployment = (
        selectedItem: Deployment | Build | undefined
    ): selectedItem is Deployment => {
        // Have to use a unique property that deployment has in order to check if it is a deployment or not
        return (selectedItem as Deployment)?.deploymentGuid !== undefined;
    };

    const getHeaderText = () => {
        if (isDeployment(selected)) {
            return selected?.displayName
                ? selected.displayName + ' Details'
                : 'Deployment Details';
        } else {
            return selected?.buildNumber
                ? selected.buildNumber + ' Details'
                : 'Build Details';
        }
    };

    return (
        <div className="DetailsPanel-root">
            <Panel
                isLightDismiss
                isOpen={props.isOpen}
                onDismiss={props.dismissPanel}
                closeButtonAriaLabel="Close"
                headerText={getHeaderText()}
                type={PanelType.custom}
                customWidth={'400px'}
            >
                <Stack tokens={stackTokens}>
                    {selected && selected.completionIndicator && (
                        <Stack>
                            <Text
                                variant={'mediumPlus'}
                                style={{
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                }}
                            >
                                Attempts
                            </Text>

                            <Dropdown
                                placeholder="Attempts"
                                onRenderOption={onRenderOption}
                                onRenderTitle={onRenderTitle}
                                onChange={onSelectAttempt}
                                defaultSelectedKey={
                                    lastSelectedOption
                                        ? lastSelectedOption.key
                                        : options.length > 0
                                        ? options[0].key
                                        : ''
                                }
                                options={options}
                            />
                        </Stack>
                    )}
                    {selected && selected.currentReplication ? (
                        <Stack tokens={stackTokens}>
                            <Stack
                                tokens={stackTokens}
                                style={{
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                }}
                            >
                                <DetailsPanelTextBox
                                    title="Type"
                                    data={
                                        isDeployment(selected)
                                            ? selected.deploymentType
                                            : selected.buildType
                                    }
                                />

                                <DetailsPanelTextBox
                                    title="Environment"
                                    data={
                                        selected.currentReplication
                                            .environmentId
                                    }
                                />

                                <DetailsPanelTextBox
                                    title="Source"
                                    data={
                                        selected.currentReplication.sourceType
                                    }
                                />

                                <DetailsPanelTextBox
                                    title="ID"
                                    data={
                                        isDeployment(selected)
                                            ? selected.deploymentGuid
                                            : selected.buildInstanceCorrelationId
                                    }
                                    tooltip={
                                        isDeployment(selected)
                                            ? 'Deployment Guid'
                                            : 'Build Instance Correlation ID'
                                    }
                                />

                                <DetailsPanelTextBox
                                    title="Correlation ID"
                                    data={
                                        selected.currentReplication
                                            ?.correlationId
                                    }
                                />
                            </Stack>

                            {show ? (
                                <CurrentReplicationTimeline
                                    currentFlightTimeline={
                                        currentFlightTimeline
                                    }
                                />
                            ) : (
                                <></>
                            )}

                            {!isDeployment(selected) ? (
                                <Link
                                    href={
                                        '#' +
                                        getBuildDetailsPath(
                                            selected.buildInstanceCorrelationId
                                        )
                                    }
                                    title="View More Details"
                                    className={'link ' + linkStyle(theme)}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'right',
                                    }}
                                >
                                    <Icon
                                        iconName="ComplianceAudit"
                                        style={{ paddingRight: '4px' }}
                                    />
                                    View More Details
                                </Link>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    ) : (
                        <></>
                    )}
                </Stack>
            </Panel>
        </div>
    );
};
