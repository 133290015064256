import React, { useContext } from 'react';
import { Text, FontWeights } from '@fluentui/react';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

export const PageBrand: React.FC = () => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    return (
        <>
            <img
                src={process.env.PUBLIC_URL + '/abLogo_cropped.svg'}
                alt={'Azure Bridge Logo'}
                width="66.67"
                height="40"
            />
            <Text
                variant="xLarge"
                styles={{
                    root: {
                        fontWeight: FontWeights.semibold,
                        padding: '0 5px 0 10px',

                        // The below 3 lines are a hack to overcome figure 11 in this article: https://iamvdo.me/en/blog/css-font-metrics-line-height-and-vertical-align
                        margin: '-3px 0 3px 0',
                        position: 'relative',
                        bottom: -1,

                        color: theme.palette.themePrimary,
                    },
                }}
            >
                Azure Bridge
            </Text>
            <Text
                variant="xSmall"
                styles={{
                    root: {
                        fontWeight: FontWeights.semibold,
                        color: theme.palette.themePrimary,
                        margin: '5px 0 0 0',
                    },
                }}
            >
                {process.env.REACT_APP_BUILD_VERSION}
            </Text>
        </>
    );
};
