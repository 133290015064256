import React, { useEffect, useRef, useState } from 'react';
import {
    SearchBox,
    ISearchBoxStyles,
    //  IDropdownOption,
    // IDropdownStyles,
} from '@fluentui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchType, SearchTypes } from './SearchTypes';
import { ChangeEvent } from 'react';
import { routeKeys } from '../../../Common/util/RouteUtils';
import { emitUserActionMetric } from '../../../Common/util/metricsUtil';
import {
    ActionResult,
    SearchValues,
    UserAction,
} from '../../../Common/Enums/Metrics/MetricEnums';

export const Search: React.FC = () => {
    const [searchType, setSearchType] = useState<SearchType>(SearchTypes[0]);
    const [searchValue, setSearchValue] = useState<string>('');

    const [searchFocused, setSearchFocused] = useState<boolean>(false);
    const searchRef = useRef<HTMLDivElement>(null);

    const history = useHistory();
    const location = useLocation();

    // Set search type based on current route
    useEffect(() => {
        const matchingType = SearchTypes.filter((s) =>
            location.pathname.startsWith(s.baseUrl)
        )[0];

        if (matchingType && matchingType.key !== searchType?.key) {
            setSearchType(matchingType);
            setSearchValue('');
        }
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    // Clear when you go Home
    useEffect(() => {
        if (location.pathname === routeKeys.Home) {
            setSearchValue('');
        }
    }, [location.pathname]);

    // Expand the search/dropdown when the search has focus
    useEffect(() => {
        const checkFocus = () => {
            if (
                searchRef.current &&
                searchRef.current.contains(document.activeElement)
            ) {
                setSearchFocused(true);
            } else {
                setSearchFocused(false);
            }
        };

        // Checks focus whenever any element on the page gets focus.
        // Note searchDiv will remain open when clicking elsewhere on the page, since that's not a focus event.
        // (We can't track focusout since it's triggered by switching between the search box and the dropdown.)
        document.addEventListener('focusin', checkFocus);
        return () => document.removeEventListener('focusin', checkFocus);
    }, []);

    // const onDropdownChange = (
    //     event: React.FormEvent<HTMLDivElement>,
    //     item?: IDropdownOption
    // ): void => {
    //     setSearchType(item as SearchType);
    // };

    const onSearchChange = (
        event?: ChangeEvent<HTMLInputElement>,
        newValue?: string
    ): void => {
        setSearchValue(newValue || '');
    };

    const onSearch = (value: string) => {
        if (searchType && value) {
            history.push({ pathname: `${searchType.searchUrl}/${value}` });
            emitUserActionMetric(
                UserAction.SEARCH_BOX,
                SearchValues.ARTIFACT,
                ActionResult.SUCCESS,
                process.env.REACT_APP_BUILD_VERSION
            );
        }
    };

    const divStyles: React.CSSProperties = {
        display: 'flex',
        margin: '6px',
    };
    // const dropdownStyles: Partial<IDropdownStyles> = {
    //     root: {
    //         width: '120px',
    //         marginRight: '-1px',
    //         visibility: searchFocused ? 'visible' : 'hidden',
    //     },
    // };
    const searchBoxStyles: Partial<ISearchBoxStyles> = {
        root: {
            width: searchFocused ? '260px' : '160px',
        },
    };

    return (
        <div style={divStyles} ref={searchRef}>
            {/* <Dropdown
                styles={dropdownStyles}
                selectedKey={searchType?.key}
                onChange={onDropdownChange}
                placeholder="Search type"
                options={SearchTypes}
                calloutProps={{ doNotLayer: true }}
            /> */}
            <SearchBox
                styles={searchBoxStyles}
                placeholder={
                    searchFocused
                        ? searchType?.placeholder || 'Select search type'
                        : 'Search'
                }
                onChange={onSearchChange}
                onSearch={onSearch}
                value={searchValue}
                disabled={!searchType}
                disableAnimation={true}
                showIcon={true}
            />
        </div>
    );
};
