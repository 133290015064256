import {
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    IGroup,
    IStackTokens,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Guid } from '../../../Common/models/Guid';
import { ServiceType } from '../../../ABSM/models/ServiceType';
import { Release } from '../../models/Release';
import { ReleaseStage } from '../../models/ReleaseStage';
import { DetailsPanel } from './Display/DetailsPanel';
import { Deployment } from '../../models/Deployment';
import { ReleaseStageDetails } from './Display/ReleaseStageDetails';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { commonGridStyle } from '../../../Common/util/tableUtils';
import { Build } from '../../models/Build';

interface IReleaseFlow {
    release: Release | undefined;
    isLoaded: boolean;
    serviceType?: ServiceType;
    serviceId?: Guid;
}

export const ReleaseFlow: React.FC<IReleaseFlow> = (props: IReleaseFlow) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const sectionStackTokens: IStackTokens = { childrenGap: 20 };
    const [stages, setStages] = useState<ReleaseStage[]>([]);
    const [selected, setSelected] = useState<Deployment | Build | undefined>();
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const showPanel = () => setIsPanelOpen(true);
    const hidePanel = () => setIsPanelOpen(false);

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            minWidth: 1700,
            isResizable: true,
            fieldName: 'environment',
        },
    ];

    // When parent component sends new data, refresh my state accordingly
    useEffect(() => {
        if (props.release && props.release.stages) {
            // Stages should be sorted before being grouped
            const sortedStagesByEnvironment = props.release.stages.sort(
                (s1, s2) => {
                    if (s1.environment && s2.environment) {
                        return s1.environment > s2.environment ? -1 : 1;
                    }

                    return 0;
                }
            );

            setStages(sortedStagesByEnvironment);

            const stagesArray = props.release.stages;

            const groupNames = [
                ...new Set(
                    props.release?.stages.map((stage) => stage.environment)
                ),
            ];

            const groupList = groupNames.map((groupName) => ({
                key: groupName?.toString() ?? 'Unknown',
                name: groupName?.toString() ?? 'Unknown',
                startIndex: stagesArray.findIndex(
                    (stage) => stage.environment === groupName
                ),
                count: stagesArray.filter(
                    (stage) => stage.environment === groupName
                ).length,
            }));

            setGroups(groupList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.release, props.isLoaded]);

    const onRenderItemColumn = (
        item: ReleaseStage,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[
            column?.fieldName as keyof ReleaseStage
        ] as string;

        switch (column?.key) {
            case 'column1':
                return (
                    <ReleaseStageDetails
                        isLoaded={props.isLoaded}
                        stage={item}
                        setSelected={setSelected}
                        showPanel={showPanel}
                    />
                );
            default:
                return <span>{fieldContent}</span>;
        }
    };

    const rowStyles = {
        root: {
            borderBottom: 'none',
        },
        cell: {
            backgroundColor: theme.palette.neutralLighterAlt,
            paddingTop: '0',
            paddingBottom: '0',
        },
    };

    const onRenderRow = (props: IDetailsRowProps | undefined) => {
        return <DetailsRow {...props!} styles={rowStyles} />;
    };

    return (
        <div className="ReleaseFlow-root">
            <Stack horizontal tokens={sectionStackTokens}>
                {/* PageTitle with flow name, submitter, and timestamp */}
                <Stack wrap tokens={sectionStackTokens}>
                    <div
                        style={{
                            height: '100vh',
                        }}
                    >
                        <ShimmeredDetailsList
                            className={commonGridStyle(theme)}
                            indentWidth={0}
                            items={stages}
                            groups={groups}
                            onRenderRow={onRenderRow}
                            onRenderItemColumn={onRenderItemColumn}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={false}
                        />
                    </div>
                </Stack>
            </Stack>
            <DetailsPanel
                isOpen={isPanelOpen}
                dismissPanel={hidePanel}
                selected={selected}
                isLoaded={props.isLoaded}
            />
        </div>
    );
};
