import { IStackTokens, IconButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import { TextWithTooltip } from '../../../../Common/components/TextWithTooltip';

interface IDetailsPanelTextBoxProps {
    title: string;
    data?: string;
    tooltip?: string;
}

export const DetailsPanelTextBox: React.FC<IDetailsPanelTextBoxProps> = (
    props: IDetailsPanelTextBoxProps
) => {
    // Tokens definition
    const stackTokens: IStackTokens = { childrenGap: 10 };

    const copyToClipboard = (data?: string) => {
        if (data) {
            navigator.clipboard.writeText(data);
        }
    };

    return (
        <Stack
            horizontal
            tokens={stackTokens}
            style={{
                justifyContent: 'space-between',
            }}
        >
            <TextWithTooltip tooltip={props.tooltip || ''} data={props.title} />
            <Stack
                horizontal
                style={{
                    border: '1px solid gray',
                    width: '65%',
                    padding: '0px 5px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Text>{props.data}</Text>
                <IconButton
                    iconProps={{ iconName: 'Copy' }}
                    onClick={() => copyToClipboard(props.data)}
                    title={'Copy to clipboard'}
                />
            </Stack>
        </Stack>
    );
};
