import React, {
    useState,
    useEffect,
    useContext,
    Dispatch,
    SetStateAction,
    useCallback,
} from 'react';
import {
    ShimmeredDetailsList,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
    IStackStyles,
    IButtonStyles,
} from '@fluentui/react';
import { MultiStageReleaseSearch } from '../MultiStageReleaseSearch';
import { ServiceNameDropDown } from '../../../../Common/components/ServiceNameDropDown';
import { CloudNameDropDown } from '../../../../Common/components/CloudNameDropDown';
import { DeploymentTypeDropDown } from '../../../../Common/components/DeploymentTypeDropDown';
import { commonGridStyle } from '../../../../Common/util/tableUtils';
import { CapabilityContext } from '../../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../../Common/components/Capabilities/capability';

import { dateLocaleStringWithTimeZone } from '../../../../Common/util/DateUtils';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import {
    ExternalLink,
    InternalLink,
} from '../../../../Common/components/Links';
import { ServiceType } from '../../../../ABSM/models/ServiceType';
import { getReleaseDetailsPath } from '../../../util/ReleaseUtils';
import { ReleaseSearchResult } from '../../../models/Release';
import {
    getAdoReleaseUrl,
    getServiceTreeUrl,
} from '../../../../Common/util/RouteUtils';
//import { ProjectNameDropDown } from '../../../../Common/components/ProjectNameDropDown';
import { CompletionStatusDisplay } from './Status/CompletionStatusDisplay';
import { PageContent } from '../../../../HubLayout/components/PageLayout/PageContent';
import { PageTitle } from '../../../../HubLayout/components/PageLayout/PageTitle';
import { DefaultButton } from 'office-ui-fabric-react';
import { AuthContext } from '../../../../HubLayout/models/AuthContext';
import { CancelToken } from 'axios';
import { setUserProfileData } from '../../../../Common/api/ABHub/UserProfileApi';
import { ReleaseExporter } from '../../../../Administration/components/Importer/ReleaseExporter';

interface IReleaseListProps {
    list: ReleaseSearchResult[];
    isLoaded: boolean;
    hideButton?: boolean;
    pageNumber?: number;
    setSearchValue?: Dispatch<SetStateAction<string | undefined>>;
    setSelectedServices?: Dispatch<SetStateAction<string[]>>;
    setSelectedEnvironments?: Dispatch<SetStateAction<string[]>>;
    setSelectedDeploymentTypes?: Dispatch<SetStateAction<string[]>>;
    setSelectedProjects?: Dispatch<SetStateAction<string[] | undefined>>;
    setPageNumber?: Dispatch<SetStateAction<number>>;
    serviceType?: ServiceType;
}

export const ReleaseList: React.FC<IReleaseListProps> = (
    props: IReleaseListProps
) => {
    const [columnsList, setColumnsList] = useState<IColumn[]>([]);
    const [items, setItems] = useState<ReleaseSearchResult[]>([]); // current version of the list - filtered or sorted

    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const stackStyles: Partial<IStackStyles> = {
        root: {
            alignItems: 'center',
            backgroundColor: theme.palette.neutralLighter,
        },
    };

    const loadMoreButtonStyles: IButtonStyles = {
        root: {
            display: props.hideButton ? 'none' : 'block',
            width: '100%',
            marginTop: '10px',
            background: theme.palette.neutralLighter,
            color: theme.palette.neutralPrimary,
        },
        rootHovered: {
            color: theme.palette.neutralPrimaryAlt,
            backgroundColor: theme.palette.neutralLight,
        },
    };

    const { authContext, setDbUpdated } = useContext(AuthContext);
    // Use capabilities context
    const capabilities = useContext(CapabilityContext);

    // Only show ADO links in public cloud
    const includeExternalLink = capabilities.check(Capability.public);

    const setRecentlyViewedRelease = useCallback(
        async (releaseCorrelationId: string, cancelToken?: CancelToken) => {
            if (authContext.userProfile) {
                const response = await setUserProfileData(
                    authContext.userProfile?.alias,
                    releaseCorrelationId,
                    cancelToken
                );

                if (setDbUpdated) {
                    setDbUpdated(true);
                }

                return response;
            }
        },
        [authContext]
    );

    const columns: IColumn[] = [
        {
            key: 'releaseId',
            name: 'Release',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'releaseName',
        },
        {
            key: 'service',
            name: 'Service',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'serviceName',
        },
        {
            key: 'completionIndicator',
            name: 'Status',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            fieldName: 'completionIndicatorString',
        },
        {
            key: 'componnentName',
            name: 'Component Name',
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
            fieldName: 'componentName',
        },
        {
            key: 'environments',
            name: 'Environment(s)',
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
            fieldName: 'environments',
        },
        {
            key: 'deploymentTypes',
            name: 'Deployment Type(s)',
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
            fieldName: 'deploymentTypes',
        },
        {
            key: 'project',
            name: 'Project',
            minWidth: 160,
            maxWidth: 160,
            isResizable: true,
            fieldName: 'projectName',
        },
        {
            key: 'createdOn',
            name: 'Created On',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'createdOn',
        },
        {
            key: 'updatedOn',
            name: 'Last Updated',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'updatedOn',
        },
    ];

    const renderReleaseColumn = (
        item: ReleaseSearchResult,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[
            column?.fieldName as keyof ReleaseSearchResult
        ] as string;

        switch (column?.key) {
            case 'service':
                return (
                    <div>
                        {item.serviceTreeName || item.serviceTreeId}
                        <ExternalLink
                            value="Service Tree"
                            url={getServiceTreeUrl(
                                item.serviceTreeId || '',
                                item.serviceTreeLevel || 'Service'
                            )}
                            title="Open Service Tree"
                        />
                    </div>
                );

            case 'releaseId':
                return (
                    <div>
                        <InternalLink
                            value={
                                item.releaseName
                                    ? item.releaseName
                                    : 'Release-' + item.releaseId
                            }
                            url={
                                '#' +
                                getReleaseDetailsPath(
                                    item.releaseCorrelationId || ''
                                )
                            }
                            title="View release details"
                            onClick={() => {
                                setRecentlyViewedRelease(
                                    item.releaseCorrelationId
                                );
                            }}
                        />
                        {includeExternalLink && (
                            <ExternalLink
                                value="ADO Release"
                                url={getAdoReleaseUrl(
                                    item.accountName || item.adoInstance || '',
                                    item.projectName || '',
                                    '',
                                    item.releaseId
                                )}
                                title="Open Azure DevOps Release Pipeline"
                            />
                        )}
                        <div
                            style={{
                                color: theme.palette.neutralTertiary,
                                fontSize: 11, //FontSizes.size12 looks too big
                            }}
                        >
                            {'Id: ' + item.releaseId}
                        </div>
                    </div>
                );

            case 'completionIndicator':
                return (
                    <CompletionStatusDisplay
                        completionIndicator={item.completionIndicator || ''}
                        completionStatusTracker={item.completionStatusTracker}
                    />
                );

            case 'createdOn':
                return dateLocaleStringWithTimeZone(item.createdOn);

            case 'updatedOn':
                return dateLocaleStringWithTimeZone(item.updatedOn);

            default:
                return <span>{fieldContent}</span>;
        }
    };

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        // Default sort by latest timestamp
        //let releases = props.list;
        //releases.sort((s1, s2) => {
        //    if (s1.updatedOn && s2.updatedOn) {
        //        return new Date(dateLocaleStringWithTimeZone(s1.updatedOn)) >
        //            new Date(dateLocaleStringWithTimeZone(s2.updatedOn))
        //            ? -1
        //            : 1;
        //    }
        //    return 0;
        //});
        setColumnsList(columns);
        setItems(props.list);
        // needs columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.list, props.isLoaded]);

    const loadMore = () => {
        if (props.setPageNumber && props.pageNumber) {
            props.setPageNumber(props.pageNumber + 1);
        }
    };

    return (
        <div className="ReleaseList-root">
            <Stack>
                <PageTitle
                    title="Latest Release Statuses"
                    subTitle={
                        props.isLoaded
                            ? items.length + ' latest releases'
                            : 'Loading...'
                    }
                />

                {capabilities.check(Capability.srmAdminRole) && (
                    <ReleaseExporter
                        releaseCorrelationIds={items.map(
                            (item) => item.releaseCorrelationId
                        )}
                    />
                )}
            </Stack>
            <PageContent>
                <Stack horizontal styles={stackStyles}>
                    <MultiStageReleaseSearch
                        setSearchValue={props.setSearchValue}
                        setPageNumber={props.setPageNumber}
                        isLoaded={props.isLoaded}
                    />
                    <DeploymentTypeDropDown
                        setSelectedDeploymentTypes={
                            props.setSelectedDeploymentTypes
                        }
                        setPageNumber={props.setPageNumber}
                        isLoaded={props.isLoaded}
                    />
                    <ServiceNameDropDown
                        setSelectedServices={props.setSelectedServices}
                        setPageNumber={props.setPageNumber}
                        isLoaded={props.isLoaded}
                    />
                    <CloudNameDropDown
                        setSelectedEnvironments={props.setSelectedEnvironments}
                        setPageNumber={props.setPageNumber}
                        isLoaded={props.isLoaded}
                    />
                    {/* <ProjectNameDropDown
                        setSelectedProjects={props.setSelectedProjects}
                        isLoaded={props.isLoaded}
                    /> */}
                </Stack>

                <ShimmeredDetailsList
                    className={commonGridStyle(theme)}
                    items={items}
                    columns={columnsList}
                    onRenderItemColumn={renderReleaseColumn}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    enableShimmer={!props.isLoaded}
                    shimmerLines={10}
                    ariaLabelForShimmer="Content is being fetched"
                    ariaLabelForGrid="Releases"
                />

                <DefaultButton
                    styles={loadMoreButtonStyles}
                    text={'Load More'}
                    onClick={loadMore}
                />

                {props.isLoaded &&
                    (props.list.length < 1 || items.length < 1) && (
                        <p>No data found.</p>
                    )}
            </PageContent>
        </div>
    );
};
