import React, {
    PropsWithChildren,
    useEffect,
    useState,
    useCallback,
    FormEvent,
    useContext,
    SetStateAction,
    Dispatch,
    CSSProperties,
} from 'react';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IComboBoxStyles,
    SelectableOptionMenuItemType,
} from '@fluentui/react';
import { getCloudsList } from '../../AGSS/api/AGSSApi';
import { CancelToken } from 'axios';
import { usePromise } from '../hooks/usePromise';
import { ThemeContext } from '../../HubLayout/models/ThemeContext';
import {
    getSelectedEnvironmentNamesFromLocalStorage,
    getThemeFromString,
    setSelectedEnvironmentNamesInLocalStorage,
} from '../util/localStorageUtils';
import { emitUserActionMetric } from '../util/metricsUtil';
import {
    ActionResult,
    FilterTypes,
    UserAction,
} from '../../Common/Enums/Metrics/MetricEnums';

interface IEnvironmentNameDropDownProps extends PropsWithChildren<any> {
    setSelectedEnvironments?: Dispatch<SetStateAction<string[]>>;
    setPageNumber?: Dispatch<SetStateAction<number>>;
    isLoaded: boolean;
}

export const CloudNameDropDown: React.FC<IEnvironmentNameDropDownProps> = (
    props: IEnvironmentNameDropDownProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [options, setOptions] = useState<IComboBoxOption[]>([]);

    const getData = useCallback(async (cancelToken?: CancelToken) => {
        return await getCloudsList(cancelToken);
    }, []);

    const [result, , dropdownLoaded] = usePromise(getData, true);

    useEffect(() => {
        if (dropdownLoaded && result) {
            let comboboxOptions: IComboBoxOption[] = [
                {
                    key: 'selectAll',
                    text: 'Select All',
                    itemType: SelectableOptionMenuItemType.SelectAll,
                },
                {
                    key: 'Header1',
                    text: 'Environment Clouds',
                    itemType: SelectableOptionMenuItemType.Header,
                },
            ];

            result.forEach((environment) => {
                var option = {
                    key: environment.id.split('-')[1],
                    text: environment.id.split('-')[1],
                } as IComboBoxOption;

                comboboxOptions.push(option);
            });

            setOptions(comboboxOptions);
        }
    }, [result, dropdownLoaded]);

    const divStyles: CSSProperties = {
        display: 'contents',
        margin: '6px',
    };

    const comboBoxStyles: Partial<IComboBoxStyles> = {
        root: {
            maxWidth: 300,
            selectors: {
                ':after': {
                    border: 'none',
                },
            },
            backgroundColor: theme.palette.neutralLighter,
        },
        container: { backgroundColor: theme.palette.neutralLighter },
        callout: { maxHeight: 300 },
        optionsContainerWrapper: { maxHeight: 300 },
        optionsContainer: {
            backgroundColor: theme.palette.neutralLighter,
        },
        input: {
            backgroundColor: theme.palette.neutralLighter,
        },
    };

    const selectableOptions = options.filter(
        (option) =>
            (option.itemType === SelectableOptionMenuItemType.Normal ||
                option.itemType === undefined) &&
            !option.disabled
    );

    const sortOptions = () => {
        const selectableOptions = options.filter(
            (option) =>
                (option.itemType === SelectableOptionMenuItemType.Normal ||
                    option.itemType === undefined) &&
                !option.disabled
        );

        const opts = selectableOptions.filter(
            (option) => option.key !== 'selectAll'
        );

        const selected = opts.filter((o) =>
            selectedKeys.includes(o.key as string)
        );
        const unselected = opts.filter(
            (o) => !selectedKeys.includes(o.key as string)
        );

        setOptions([
            {
                key: 'selectAll',
                text: 'Select All',
                itemType: SelectableOptionMenuItemType.SelectAll,
            },
            {
                key: 'Header1',
                text: 'Environment Names',
                itemType: SelectableOptionMenuItemType.Header,
            },
            ...selected.concat(unselected),
        ]);
    };

    const onChange = (
        event: FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string
    ) => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeys.filter(
            (key) => key !== 'selectAll'
        );
        const selectAllState =
            currentSelectedOptionKeys.length === selectableOptions.length;

        if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                if (selectAllState) {
                    setSelectedKeys([]);
                    setSelectedEnvironmentNamesInLocalStorage([]);
                } else {
                    setSelectedKeys([
                        'selectAll',
                        ...selectableOptions.map((o) => o.key as string),
                    ]);
                    setSelectedEnvironmentNamesInLocalStorage([
                        'selectAll',
                        ...selectableOptions.map((o) => o.key as string),
                    ]);
                }
            } else {
                const updatedKeys = selected
                    ? [...currentSelectedOptionKeys, option!.key as string]
                    : currentSelectedOptionKeys.filter((k) => k !== option.key);
                if (updatedKeys.length === selectableOptions.length) {
                    updatedKeys.push('selectAll');
                }
                setSelectedKeys(updatedKeys);
                setSelectedEnvironmentNamesInLocalStorage(updatedKeys);
            }

            emitUserActionMetric(
                UserAction.DROPDOWN_FILTER,
                FilterTypes.ENVIRONMENT_NAME,
                ActionResult.SUCCESS,
                process.env.REACT_APP_BUILD_VERSION
            );
        }
    };

    useEffect(() => {
        const selectedKeysFromStorage =
            getSelectedEnvironmentNamesFromLocalStorage();

        if (selectedKeysFromStorage) {
            selectedKeysFromStorage.forEach((key) => {
                if (!selectedKeys.includes(key)) {
                    selectedKeys.push(key);
                }
            });
        }

        const currentSelectedOptionKeys = selectedKeys.filter(
            (key) => key !== 'selectAll'
        );

        if (props.setSelectedEnvironments && props.setPageNumber) {
            if (selectedKeys.length === options.length - 1) {
                setSelectedEnvironmentNamesInLocalStorage([]);
                props.setSelectedEnvironments([]);
                props.setPageNumber(1);
            } else {
                props.setSelectedEnvironments(currentSelectedOptionKeys);
                props.setPageNumber(1);
            }
        }

        sortOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedKeys, props.setSelectedEnvironments]);

    useEffect(() => {
        setDisabled(!props.isLoaded);
        let comboboxOptions = options.map((option) => {
            return {
                ...option,
                disabled: props.isLoaded ? false : true,
            };
        });
        setOptions(comboboxOptions);
        sortOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoaded]);

    return (
        <div style={divStyles}>
            {result && (
                <ComboBox
                    multiSelect
                    allowFreeform
                    placeholder="Environment Name"
                    options={options}
                    selectedKey={selectedKeys}
                    onChange={onChange}
                    styles={comboBoxStyles}
                    disabled={disabled}
                />
            )}
        </div>
    );
};
